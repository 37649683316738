var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, inject, computed, onMounted, onBeforeMount, reactive, ref, } from 'vue';
import { IonContent, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonPage, } from '@ionic/vue';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import i18n from '@/i18n';
import { useRouter } from 'vue-router';
export default defineComponent({
    components: {
        IonContent: IonContent,
        IonInput: IonInput,
        IonItem: IonItem,
        IonLabel: IonLabel,
        IonSelect: IonSelect,
        IonSelectOption: IonSelectOption,
        IonPage: IonPage,
    },
    setup: function () {
        var _this = this;
        var authStore = inject('authState');
        var userData = computed(function () { return authStore.state.userData; });
        var tradeDoublerId = computed(function () { return authStore.state.tduid; });
        var configurationStore = inject('configurationStore');
        var mobileValidationFormData = reactive(configurationStore.state.phone.mobileValidationFormData);
        var newMobileFormData = reactive(configurationStore.state.phone.newMobileFormData);
        var signupStep2FormData = reactive(configurationStore.state.signupStep2FormData);
        var provinces = computed(function () { return configurationStore.state.details.provinces; });
        var genders = computed(function () { return signupStep2FormData.genders; });
        var countries = computed(function () { return configurationStore.state.details.countries; });
        var newMobileIsSelected = ref(false);
        var mobileValidationIsSelected = ref(false);
        var register2 = ref(false);
        // TODO: Extract validators to their own file
        var _a = useField('nickname', yup.string().required(i18n.global.t('FIELD_REQUIRED'))), nickError = _a.errorMessage, nickname = _a.value;
        var _b = useField('gender', yup.string().required(i18n.global.t('FIELD_REQUIRED'))), genderError = _b.errorMessage, gender = _b.value;
        var _c = useField('province', yup.number().required(i18n.global.t('FIELD_REQUIRED'))), provinceError = _c.errorMessage, province = _c.value;
        var _d = useField('postalCode', yup
            .string()
            .required()
            .matches(authStore.state.userData.residenceCountry.elementId == 0 ? /^[0-9]+$/ : /^\d{4}-\d{3}$/, i18n.global.t('WRONG_POSTALCODE_FORMAT'))
            .min(5, i18n.global.t('WRONG_POSTALCODE_FORMAT'))
            .max(authStore.state.userData.residenceCountry.elementId == 0 ? 5 : 9, i18n.global.t('WRONG_POSTALCODE_FORMAT'))), postalCodeError = _d.errorMessage, postalCode = _d.value;
        var register2FormValid = computed(function () {
            return !!nickname.value &&
                !nickError.value &&
                !!gender.value &&
                !genderError.value &&
                Number(province.value) >= 0 &&
                !provinceError.value &&
                !!postalCode.value &&
                !postalCodeError.value;
        });
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (authStore.state.userData.mobileNum != null) {
                    configurationStore.resendMobileConfirmation();
                }
                return [2 /*return*/];
            });
        }); });
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            var provinces, countries;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, authStore.getUserDetails()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, configurationStore.getElementsWithFilter(authStore.state.userData.residenceCountry.secondaryValue)];
                    case 2:
                        provinces = _a.sent();
                        if (!provinces) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error recuperando provincias',
                            });
                        }
                        return [4 /*yield*/, configurationStore.getElements(configurationStore.state.details.Countries)];
                    case 3:
                        countries = _a.sent();
                        if (!countries) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error recuperando países',
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        var router = useRouter();
        var goTo = function (name) {
            router.push({ name: name });
        };
        var newMobileFormSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configurationStore.state.phone.newMobileFormData.showSuccess = false;
                        configurationStore.state.phone.newMobileFormData.showError = false;
                        if (!(configurationStore.state.phone.newMobileFormData.mobile != null &&
                            configurationStore.state.phone.newMobileFormData.mobile.toString().length != 9)) return [3 /*break*/, 1];
                        configurationStore.state.phone.newMobileFormData.showError = true;
                        configurationStore.state.phone.newMobileFormData.errorMessage = 'tester_mobile_not_valid';
                        return [3 /*break*/, 3];
                    case 1:
                        data = {
                            mobile: configurationStore.state.phone.newMobileFormData.mobile,
                        };
                        return [4 /*yield*/, configurationStore.newMobile(data)];
                    case 2:
                        success = _a.sent();
                        if (success === true) {
                            authStore.getUserDetails();
                            Swal.fire({
                                icon: 'success',
                                title: i18n.global.t('tester_mobile_validated'),
                                text: i18n.global.t('tester_email_validate_mobile')
                            });
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var setErrorMessage = function (errorCode) {
            switch (errorCode) {
                case 'nickname_is_already_in_use':
                    return i18n.global.t('tester_personal_data_nickname_error');
                case 'tester_pin_code_error':
                    return i18n.global.t('tester_pin_code_error');
                case 'tester_mobile_not_valid':
                    return i18n.global.t('tester_mobile_not_valid');
                case 'registration_mobile_fraud':
                    return i18n.global.t('tester_duplicate_mobile_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_default_error');
            }
        };
        var checkDateInputs = function (dateValue) {
            var userDay = parseInt(dateValue.day, 10);
            var userMonth = parseInt(dateValue.month, 10);
            var userYear = parseInt(dateValue.year, 10);
            var currentYear = new Date().getFullYear();
            if ((currentYear - userYear) >= 100 || userMonth < 1 || userDay < 1) {
                return true;
            }
            else {
                return false;
            }
        };
        var resendMobileConfirmation = function () {
            var success = configurationStore.resendMobileConfirmation();
            if (success) {
                Swal.fire({
                    icon: 'success',
                    title: i18n.global.t('tester_mobile_validation_resend'),
                    text: i18n.global.t('tester_email_validate_mobile_now')
                });
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                });
            }
        };
        var mobileValidationFormSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configurationStore.state.phone.mobileValidationFormData.showSuccess =
                            false;
                        configurationStore.state.phone.mobileValidationFormData.showError =
                            false;
                        data = {
                            validationCode: configurationStore.state.phone.mobileValidationFormData
                                .mobileValidationCode,
                        };
                        return [4 /*yield*/, configurationStore.validateMobile(authStore.state.userData.identifier, data)];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            authStore.getUserDetails();
                            if (authStore.state.userData.profileCompleted) {
                                goTo('tests.index');
                                return [2 /*return*/];
                            }
                            authStore.state.userData.mobileValidated = true;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var signupSubmitStep2 = function () { return __awaiter(_this, void 0, void 0, function () {
            var bornDate, data, succes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!register2FormValid.value)
                            return [2 /*return*/];
                        signupStep2FormData.showSuccess = false;
                        signupStep2FormData.showError = false;
                        signupStep2FormData.showInvalidDate = false;
                        bornDate = moment("".concat(signupStep2FormData.birthday.year, "-").concat(signupStep2FormData.birthday.month, "-").concat(signupStep2FormData.birthday.day), 'YYYY-MM-DD');
                        if (!bornDate.isValid() || checkDateInputs(signupStep2FormData.birthday)) {
                            signupStep2FormData.showInvalidDate = true;
                            return [2 /*return*/];
                        }
                        if (moment().diff(bornDate, 'years') < 16) {
                            Swal.fire({
                                icon: 'warning',
                                title: i18n.global.t('tester_age_min'),
                                text: i18n.global.t('tester_age_min_msg'),
                            });
                            return [2 /*return*/];
                        }
                        data = {
                            nickname: nickname.value,
                            gender: gender.value,
                            province: {
                                elementId: province.value,
                            },
                            bornDate: bornDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                            postalCode: postalCode.value,
                            extraData: 'bypassProfile',
                        };
                        return [4 /*yield*/, configurationStore.register2(data)];
                    case 1:
                        succes = _a.sent();
                        if (!succes) {
                            return [2 /*return*/];
                        }
                        if (authStore.state.userData.referralCode == "adsalsa")
                            authStore.sendAdSalsaPixel();
                        authStore.getUserDetails();
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            userData: userData,
            tradeDoublerId: tradeDoublerId,
            mobileValidationFormData: mobileValidationFormData,
            newMobileFormData: newMobileFormData,
            newMobileIsSelected: newMobileIsSelected,
            mobileValidationIsSelected: mobileValidationIsSelected,
            newMobileFormSubmit: newMobileFormSubmit,
            resendMobileConfirmation: resendMobileConfirmation,
            mobileValidationFormSubmit: mobileValidationFormSubmit,
            provinces: provinces,
            genders: genders,
            signupStep2FormData: signupStep2FormData,
            signupSubmitStep2: signupSubmitStep2,
            register2: register2,
            countries: countries,
            nickname: nickname,
            nickError: nickError,
            gender: gender,
            genderError: genderError,
            province: province,
            provinceError: provinceError,
            postalCode: postalCode,
            postalCodeError: postalCodeError,
            register2FormValid: register2FormValid,
            goTo: goTo,
            setErrorMessage: setErrorMessage,
            checkDateInputs: checkDateInputs
        };
    },
});
